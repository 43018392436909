import React, { lazy, Suspense} from "react";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { BuilderPage } from "./Admin/pages/BuilderPage";
import { LocationPage } from "./Admin/pages/LocationPage";
import { DashboardPage } from "./Admin/pages/DashboardPage";
import SubscriptionTabPage from "./Admin/pages/SubscriptionTabPage";
import MyResultsTabPage from "./Admin/pages/MyResultsTabPage";
import { ComplaintPage } from "./Admin/pages/ComplaintPage";
import { Supervisor } from "./Admin/pages/SupervisorPage";
import { ADMIN_URL } from "../enums/constant";
import { EmployeePage } from "./Admin/pages/EmployeePage";
import { shallowEqual, useSelector } from "react-redux";
import { FeedbackPage } from "./Admin/modules/Feedback/components/FeedbackPage";
import { AttendancePage } from "./Admin/pages/AttendancePage";
import { ViolationPage } from "./Admin/pages/ViolationPage";
import { AllNotificationPage } from "./Admin/pages/AllNotificationPage";
import { CameraPage } from "./Admin/pages/CameraPage";
import Cookies from "universal-cookie";
import MyEventsTabPage from "./Admin/pages/MyEventsTabPage";
import AllCameraPage from "./Admin/pages/AllCameraPage";



const ModelCategoriesTabPage = lazy(() =>
  import("./Admin/pages/ModelCategoriesTabPage")
);


export default function AdminBasePage() {
  // const dispatch = useDispatch();
  // const [user1, setUser] = useState(false);

  const { user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user?.id && new Cookies().get("access_token"),
      user: auth.user
    }),
    shallowEqual
  );


//   useEffect(() => {
//     if (user1 === "") {
//       getAllDeployedRTSPJobsDetails()
//         .then(response => {
//           if (response && response.isSuccess) {
//             dispatch(setSubscription(true));
//             setUser(true);
//           }
//         })
//         .catch(error => {
//           setUser(false);
//           dispatch(setSubscription(false));
//           if (error.detail) {
//             warningToast(error.detail);
//           } else {
//             warningToast("Something went Wrong");
//           }
//         });
//     }
//
  // }, []);


  return (
    <>

      {!user ? (
        <>
          <ContentRoute
            path={ADMIN_URL + "/model-categories"}
            component={ModelCategoriesTabPage}
          />
          <ContentRoute
            path={ADMIN_URL + "/locations"}
            component={LocationPage}
          />
          <ContentRoute
            path={ADMIN_URL + "/employee"}
            component={EmployeePage}
          />
          <ContentRoute
            path={ADMIN_URL + "/addSupervisor"}
            component={Supervisor}
          />
          <ContentRoute
            path={ADMIN_URL + "/attendance"}
            component={AttendancePage}
          />
          <ContentRoute
            path={ADMIN_URL + "/violation"}
            component={ViolationPage}
          />
          <ContentRoute
            path={"/allNotification"}
            component={AllNotificationPage}
          />
          <ContentRoute
            path={ADMIN_URL + "/subscriptions"}
            component={SubscriptionTabPage}
          />
          <ContentRoute path={"/complaints"} component={ComplaintPage} />
          <ContentRoute path="/feedbacks" component={FeedbackPage} />
        </>
      ) : null}
      {user && (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ContentRoute
            path={ADMIN_URL + "/dashboard"}
            component={DashboardPage}
          />
          <ContentRoute path={ADMIN_URL + "/cameras"} component={CameraPage} />
          <ContentRoute
            path={ADMIN_URL + "/model-categories"}
            component={ModelCategoriesTabPage}
          />
          <ContentRoute
            path={ADMIN_URL + "/subscriptions"}
            component={SubscriptionTabPage}
          />
          <ContentRoute path={"/my-results"} component={MyResultsTabPage} />
          <ContentRoute path={"/my-events"} component={MyEventsTabPage} />
          <ContentRoute path={ADMIN_URL + "/builder"} component={BuilderPage} />
          <ContentRoute
            path={ADMIN_URL + "/locations"}
            component={LocationPage}
          />
          <ContentRoute path={"/complaints"} component={ComplaintPage} />
          <ContentRoute
            path={ADMIN_URL + "/addSupervisor"}
            component={Supervisor}
          />
          <ContentRoute
              path={ADMIN_URL + "/allCamera"}
              component={AllCameraPage}
          />
          <ContentRoute
            path={ADMIN_URL + "/employee"}
            component={EmployeePage}
          />
          <ContentRoute
            path={ADMIN_URL + "/attendance"}
            component={AttendancePage}
          />
          <ContentRoute
            path={ADMIN_URL + "/violation"}
            component={ViolationPage}
          />
          <ContentRoute
            path={"/allNotification"}
            component={AllNotificationPage}
          />
          <ContentRoute path="/feedbacks" component={FeedbackPage} />
        </Suspense>
      )}
    </>
  );
}
