import React from "react";
import { Modal } from "react-bootstrap";
import { ComplaintEditDialogHeader } from "./ComplaintEditDialogHeader";
import { ComplaintEditForm } from "./ComplaintEditForm";
import * as action from "../../_redux/ComplaintAction";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import BlockUi from "react-block-ui";

export function ComplaintEditDialog({ id, show, onHide }) {
  const dispatch = useDispatch();

  const { actionsLoading } = useSelector(
    state => ({
      actionsLoading: state.complaint.actionsLoading
    }),
    shallowEqual
  );

  const saveComplaintDetails = complaint => {
    dispatch(action.createComplaint(complaint)).then(() => onHide());
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <ComplaintEditDialogHeader id={id} />
      <BlockUi tag="div" blocking={actionsLoading} color="#147b82">
        <ComplaintEditForm
          saveComplaint={saveComplaintDetails}
          onHide={onHide}
        />
      </BlockUi>
    </Modal>
  );
}
