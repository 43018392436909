/* eslint-disable */
import React, {useEffect} from "react";
import {Modal} from "react-bootstrap";
import Button from '@material-ui/core/Button';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchComplaintById} from "../../_redux/ComplaintAction";


export function ComplaintImageViewDialog({id, show, onHide}) {

    const dispatch = useDispatch();

    const {complaintFetchedById} = useSelector(
        (state) => ({
            complaintFetchedById: state.complaint.complaintFetchedById
        }),
        shallowEqual
    );

    useEffect(() => {
        if (id) {
            dispatch(fetchComplaintById(id));
        }
        // eslint-disable-next-line
    }, [id])

    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="result-modal"
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Complaint Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"complaint-image-display"}>
                {complaintFetchedById?.img_url ?
                    <img className="w-100" src={complaintFetchedById?.img_url} alt={"Complaint Image"}/>
                    :
                    <b>No Image Uploaded For This Complaint</b>
                }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
