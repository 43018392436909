import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { warningToast } from "../../../../../../../../utils/ToastMessage";
import { updateCameraSettingRTSPStatus } from "../../../../_redux/DeployedRTSPJobs/DeployedRTSPJobsSettingsAPI";
import * as actions from "../../../../_redux/DeployedRTSPJobs/DeployedRTSPJobsAction";
import DeployedRTSPJobsCameraSetting from "../../../../../Modal/DeployedRTSPJobsCameraSettingModal";

export function DeploymentRTSPJobsCameraSettingsDialog({ id, show, onHide }) {
  const dispatch = useDispatch();
  const { entities } = useSelector(
    state => ({
      entities: state.deploymentRTSPJobs.entities
    }),
    shallowEqual
  );

  const [settings, setSettings] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [newSetting, setNewSetting] = useState({});
  const [rtspId, setRtspId] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (id) {
      const rtspJob = entities?.filter(job => job.id === id * 1);
      if (rtspJob?.length) {
        setSettings(rtspJob[0]?.camera_settings || []);
        setRtspId(rtspJob[0]?.id || []);
      }
    }
    // eslint-disable-next-line
  }, [id, entities]);

  const changeCameraSettingStatus = () => {
    updateCameraSettingRTSPStatus(newSetting.id, newSetting.status)
      .then(response => {
        if (response && response.isSuccess) {
          dispatch(actions.fetchDeployedRTSPJobs()).then(() =>
            setIsSuccess(true)
          );
          setTimeout(() => {
            setShowAlert(false);
            setIsSuccess(false);
          }, 800);
        } else {
          throw new Error("Error while updating camera settings for this job");
        }
      })
      .catch(error => {
        warningToast("Something went wrong");
      });
  };

  const ShowAlertForSetting = (id, status) => {
    setNewSetting({ id, status });
    setShowAlert(true);
  };

  return (
    <Modal
      size="lg"
      isOpen={show}
      toggle={() => onHide(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <ModalHeader closeButton>
        <h3 id="example-modal-sizes-title-lg">Job Camera Settings</h3>
      </ModalHeader>
      <ModalBody>
        <DeployedRTSPJobsCameraSetting
          onHide={onHide}
          isSuccess={isSuccess}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          setIsSuccess={setIsSuccess}
          changeCameraSettingStatus={changeCameraSettingStatus}
          ShowAlertForSetting={ShowAlertForSetting}
          rtspId={rtspId}
          settings={settings}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={() => onHide(false)}
          className="btn btn-light btn-elevate"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
