import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button } from "reactstrap";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Boundingbox from "image-bounding-box-custom";
import { Col, Modal, Row } from "react-bootstrap";
import * as moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import CardMedia from "@material-ui/core/CardMedia";
import { withStyles } from "@material-ui/core/styles";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const styles = theme => ({
  card: {
    maxWidth: 416,
    height: "100%",
    margin: "auto"
  },
  media: {
    height: 230
  },
  header: {
    paddingBottom: "0rem"
  },
  learnMore: {
    position: "absolute",
    bottom: 0
  },
  cardCol: {
    height: 220,
    marginTop: 25,
    marginBottom: 15
  }
});

class DashboardTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: props.tableData,
      showBarTable: props.showBarTable,
      dashboardGraphName: props.dashboardGraphName,
      columns: null,
      data: null,
      viewImageModal: false,
      cameraName: "cam2",
      cameraData: props.cameraData,
      dataForCol: [],
      show: false
    };
  }

  componentDidMount() {
    let columns = [];
    if (this.props.dashboardGraphName === "Label") {
      columns = [
        {
          dataField: "camera_name",
          text: "camera"
        },
        {
          dataField: "count",
          text: "Count"
        },
        {
          dataField: "date",
          text: "Date"
        },
        {
          dataField: "labels",
          text: "labels"
        }
      ];
    } else {
      columns = [
        {
          dataField: "event_name",
          text: "event"
        },
        {
          dataField: "event_type",
          text: "event type"
        },
        {
          dataField: "event_desc",
          text: "event description",
          formatter: event_desc => {
            return (
              <>
                <Tooltip
                  className="tools"
                  title={<div className="tooltip-font">{event_desc}</div>}
                  placement={"bottom"}
                >
                  <div
                    style={{ width: "150px" }}
                    className="short-label-name-length"
                  >
                    {event_desc}
                  </div>
                </Tooltip>
              </>
            );
          }
        },
        {
          dataField: "camera_name",
          text: "camera name",
          style: {
            minWidth: "55px"
          }
        },
        {
          dataField: "event_date",
          text: "Event Date"
        }
      ];
    }

    this.getColumnsAndData();
    this.setState({
      columns: columns
    });
  }

  viewImageModalFun = id => {
    this.onHide();
  };

  getColumnsAndData = () => {
    let data = [];
    if (this.props.dashboardGraphName === "Label") {
      let camera_name = this.state.cameraData[
        this.state.tableData[0].camera_id
      ];
      let count = this.state.tableData[0].result.detection.length;
      let Date = moment
        .utc(this.state.tableData[0].created_date.$date)
        .local()
        .format("MMMM DD YYYY, h:mm:ss a");
      let labels = Object.keys(this.state.tableData[0].counts).toString();
      data.push({
        camera_name: camera_name,
        count: count,
        date: Date,
        labels: labels
      });
    } else {
      let camera_name = this.state.cameraData[
        this.state.tableData[0]?.camera_id
      ];
      let event_name = this.state.tableData[0]?.event_name;
      let event_desc = this.state.tableData[0]?.event_desc;
      let event_type = this.state.tableData[0]?.event_type;
      let Date = moment
        .utc(this.state.tableData[0]?.event_date.$date)
        .local()
        .format("MMMM DD YYYY, h:mm:ss a");
      data.push({
        event_name: event_name,
        event_desc: event_desc,
        event_type: event_type,
        camera_name: camera_name,
        event_date: Date
      });
    }
    this.setState({
      data: data
    });
  };

  onHide = () => {
    this.setState({
      showBarTable: !this.state.showBarTable,
      viewImageModal: !this.state.viewImageModal
    });
  };

  modalOpen = () => {
    this.setState({
      show: true
    });
  };

  onHideClick = () => {
    this.setState({
      show: false
    });
  };

  render() {
    const { classes ,user } = this.props;
    return (
      <Modal
        size="xl"
        show={this.state.showBarTable}
        onHide={this.onHide}
        dialogClassName="result-modal modalNewSize"
      >
        <Modal.Header closeButton={this.onHide}>
          <h3 id="example-modal-sizes-title-lg">
            {this.props.dashboardGraphName === "Label"
              ? "My Result Details"
              : "My Events Details"}
          </h3>
        </Modal.Header>

        <Modal.Body style={{ padding: "0.50rem" }}>
          {this.state.data && (
            <BootstrapTable
              classes="table table-head-custom table-vertical-center overflow-hidden mt-3"
              bootstrap4
              wrapperClasses="table-responsive"
              keyField="label"
              bordered={false}
              data={this.state.data}
              columns={this.state.columns}
            ></BootstrapTable>
          )}
          {this.props.dashboardGraphName === "Label" ? (
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={200}
              defaultPositionY={100}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <div
                    className="tools"
                    style={{ width: "100%", marginBottom: "4px" }}
                  >
                    <ButtonGroup
                      size="small"
                      aria-label="Small outlined button group"
                    >
                      <Button onClick={zoomIn}>+</Button>
                      <Button onClick={zoomOut}>-</Button>
                      <Button onClick={resetTransform}>reset</Button>
                    </ButtonGroup>
                  </div>
                  <div
                    className="boundimage-full w-100"
                    style={{ margin: "0 auto" }}
                  >
                    <TransformComponent>
                      <Boundingbox
                        className="row m-auto col-12 p-0 text-center"
                        image={this.state.tableData[0]?.image_url}
                        boxes={this.state.tableData[0]?.result?.detection.map(
                          d => ({
                            coord: [
                              d.location[0],
                              d.location[1],
                              d.location[2] - d.location[0],
                              d.location[3] - d.location[1]
                            ],
                            label: user.user_email !== 'fieldai_admin@tusker.ai' && d.label
                          })
                        )}
                        showLabels={false}
                        options={{
                          colors: {
                            normal: "red",
                            selected: "red",
                            unselected: "red"
                          },
                          style: {
                            maxWidth: "100%",
                            maxHeight: "90vh",
                            margin: "auto",
                            width: 752,
                            color: "red",
                            height: 470
                          }
                        }}
                      />
                    </TransformComponent>
                  </div>
                </React.Fragment>
              )}
            </TransformWrapper>
          ) : (
            <>
                {this.state.tableData[0]?.image_list && this.state.tableData[0]?.image_list?
                <Row>
                  <Col xl={6} md={6} sm={12} lg={6}>
                    <TransformWrapper defaultScale={1} defaultPositionX={200} defaultPositionY={100}>
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                          <React.Fragment>
                            <div className="tools" style={{ width: "100%", marginBottom: "4px" }}>
                              <ButtonGroup size="small" aria-label="Small outlined button group">
                                <Button onClick={zoomIn}>+</Button>
                                <Button onClick={zoomOut}>-</Button>
                                <Button onClick={resetTransform}>reset</Button>
                              </ButtonGroup>
                            </div>
                            <div
                                className="boundimage-full w-100"
                                style={{ margin: "0 auto" }}
                            >
                              <TransformComponent>
                                <div className={"mt-5 mb-5"}>
                                  <CardMedia
                                      style={{ cursor: "pointer" }}
                                      // className={classes.media}
                                      alt={"Image Here 1"}
                                      // onClick={modalOpen}
                                  >
                                    <Boundingbox
                                        className="row m-auto col-12 p-0 text-center"
                                        image={this.state.tableData[0]?.image_list[0]?.imageUrl ? this.state.tableData[0]?.image_list[0]?.imageUrl : this.state.tableData[0]?.image_list[0]}
                                        options={{
                                          colors: {
                                            normal: "red",
                                            selected: "red",
                                            unselected: "red"
                                          },
                                          style: {
                                            maxWidth: "100%",
                                            maxHeight: "100vh",
                                            margin: "auto",
                                            width: "100vw",
                                            // width: 358,
                                            color: "red",
                                            height: 510
                                          }
                                        }}
                                    />

                                  </CardMedia>
                                </div>
                              </TransformComponent>
                            </div>
                          </React.Fragment>
                      )}
                    </TransformWrapper>
                  </Col>
                  <Col xl={6} md={6} sm={12} lg={6}>
                    <TransformWrapper defaultScale={1} defaultPositionX={200} defaultPositionY={100}>
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                          <React.Fragment>
                            <div
                                className="tools"
                                style={{ width: "100%", marginBottom: "4px" }}
                            >
                              <ButtonGroup
                                  size="small"
                                  aria-label="Small outlined button group"
                              >
                                <Button onClick={zoomIn}>+</Button>
                                <Button onClick={zoomOut}>-</Button>
                                <Button onClick={resetTransform}>reset</Button>
                              </ButtonGroup>
                            </div>
                            <div
                                className="boundimage-full w-100"
                                style={{ margin: "0 auto" }}
                            >
                              <TransformComponent>
                                <div className={"mt-5 mb-5"}>
                                  <CardMedia
                                      style={{ cursor: "pointer" }}
                                      alt={"Image Here 1"}
                                  >
                                    <Boundingbox
                                        className="row m-auto col-12 p-0 text-center"
                                        image={this.state.tableData[0]?.image_list[this.state.tableData[0]?.image_list.length-1]?.imageUrl ? this.state.tableData[0]?.image_list[this.state.tableData[0]?.image_list.length-1]?.imageUrl : this.state.tableData[0]?.image_list[this.state.tableData[0]?.image_list.length-1]}
                                        options={{
                                          colors: {
                                            normal: "red",
                                            selected: "red",
                                            unselected: "red"
                                          },
                                          style: {
                                            maxWidth: "100%",
                                            maxHeight: "100vh",
                                            margin: "auto",
                                            width: "100vw",
                                            // width: 358,
                                            color: "red",
                                            height: 510
                                          }
                                        }}
                                    />

                                  </CardMedia>
                                </div>
                              </TransformComponent>
                            </div>
                          </React.Fragment>
                      )}
                    </TransformWrapper>
                  </Col>
                </Row> : <></>}

            </>
          )}
          {/*</div>*/}
        </Modal.Body>
        <Modal.Footer style={{ display: "block" }}>
          <Row>
          <Col xl={6} lg={6} xs={12} md={6} sm={12}>
            {this.state.tableData[0]?.image_list && this.state.tableData[0]?.image_list ?
            <span className={"font-weight-bolder"}>
              Entry Time :{" "}
              {moment
                  .utc(this.state.tableData[0]?.image_list[0]?.imageDate)
                  .local()
                  .format("DD MMMM YYYY, h:mm:ss a")}{" "}
              - Exit Time :{" "}
              {moment
                  .utc(this.state.tableData[0]?.image_list[this.state.tableData[0]?.image_list?.length - 1]?.imageDate)
                  .local()
                  .format("DD MMMM YYYY, h:mm:ss a")}{" "}
            </span>:<></>}

          </Col>
          <Col
              xl={6}
              lg={6}
              xs={12}
              md={6}
              sm={12}
              className={"d-flex justify-content-end"}
          >
            <Button
                type="button"
                onClick={this.onHide}
                className="btn btn-light btn-elevate"
            >
              Close
            </Button>
          </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withStyles(styles)(DashboardTable);
