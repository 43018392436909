export const boundBoxOptions = {
  colors: {
    normal: "red",
    selected: "red",
    unselected: "red"
  },
  style: {
    maxWidth: "100%",
    maxHeight: "90vh",
    margin: "auto",
    width: 752,
    color: "red",
    height: 470
  }
};
