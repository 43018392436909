import React from "react";
import {Modal} from "react-bootstrap";

export function ComplaintEditDialogHeader() {

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Add NewComplaint</Modal.Title>
            </Modal.Header>
        </>
    );
}
