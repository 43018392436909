import React, { Component, Fragment } from "react";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Input
} from "reactstrap";

import { addSupervisor } from "../AddSupervisor/_redux";
import { successToast, warningToast } from "../../../../utils/ToastMessage";
import { connect } from "react-redux";
import * as auth from "../Auth";
import { addNotification } from "../Notification/_redux/notification";

class AddSupervisorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: props.modalOpen
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      modalOpen: nextProps.modalOpen
    });
  }

  addSupervisorToList = () => {
    const { user } = this.props;

    let param = {
      user_email: this.state.user_email,
      user_status: true,
      user_password: this.state.user_password
    };

    if (
      this.state.user_email &&
      this.state.user_email.trim() !== "" &&
      this.state.user_password &&
      this.state.user_password.trim() !== ""
    ) {
      // eslint-disable-next-line
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.user_email)) {
        addSupervisor(param)
          .then(response => {
            if (response && response.isSuccess) {
              this.props.toggleSupervisorModal();

              let data1 = {
                notification_message:
                  "Supervisor Added : " + this.state.user_email,
                user_id: user.id,
                type_of_notification: "string",
                status: true,
                is_unread: true
              };
              addNotification(data1)
                .then(response => {
                  if (response && response.isSuccess) {
                    successToast("Supervisor Added Successfully");
                  }
                })
                .catch(error => {
                  if (error.detail) {
                    warningToast(error.detail);
                  }
                });
            }
          })
          .catch(error => {
            if (error.detail) {
              warningToast(error.detail);
            } else {
              warningToast("Something went wrong");
            }
          });
      } else {
        warningToast("Please add valid email");
      }
    } else {
      warningToast("Please fill required fields");
    }
  };

  handleOnChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    return (
      <Fragment>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.props.toggleSupervisorModal}
          backdrop="static"
        >
          <ModalHeader>
            <div style={{ width: "100%", float: "left" }}>
              <h2>Add Supervisor</h2>
            </div>
          </ModalHeader>
          <ModalBody>
            <Label for="email">Email *</Label>
            <Input
              onChange={this.handleOnChange}
              type="email"
              name="user_email"
            />
            <Label for="password">Password *</Label>
            <Input
              onChange={this.handleOnChange}
              type="password"
              name="user_password"
            />
          </ModalBody>
          <ModalFooter>
            <Row className={"m-0"} style={{ width: "100%" }}>
              <Col className={"p-0"} xl={12}>
                <div style={{ width: "100%", textAlign: "end" }}>
                  <Button
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    className={"mr-2 btn-apply-filter"}
                    onClick={this.addSupervisorToList}
                    size="lg"
                  >
                    Add Supervisor
                  </Button>
                  <Button
                    className={"close-filter-btn"}
                    onClick={this.props.toggleSupervisorModal}
                    size="lg"
                  >
                    Close
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return { user: auth.user };
}

export default connect(mapStateToProps, auth.actions)(AddSupervisorModal);
