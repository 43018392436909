import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Input} from "reactstrap";
import {warningToast} from "../../../../../utils/ToastMessage";
import {updateNotificationServiceUserConfig} from "../../../../SuperAdmin/modules/CompanyService/_redux";


function WhatsappEdit({show , onHide ,disabled ,userAccessEditData ,getNotificationServiceConfigByUserID ,id }) {
    const [dataEnter, setDataEnter] = useState("");

    const handleKeyPress = event => {
        if (event.key === "Enter") {
            setDataEnter(event.target.value);
            event.target.value = "";
        }
    };

    useEffect(() => {
        if (dataEnter !== undefined && dataEnter !== null) {
                updateNotificationServiceUserByConfig(dataEnter);
            }
    }, [dataEnter]);

    const updateNotificationServiceUserByConfig = dataEnter => {
        if (userAccessEditData && dataEnter ) {
            let body = {
                id: userAccessEditData?.id,
                config: dataEnter
            };
            updateNotificationServiceUserConfig(body)
                .then(response => {
                    if (response && response.isSuccess) {
                        getNotificationServiceConfigByUserID(id, 1, 5);
                        onHide()
                    }
                })
                .catch(e => {
                    if (e.detail) {
                        warningToast(e.detail);
                    } else {
                        warningToast("Something went wrong");
                    }
                });
        }
    };

    return (
        <div>
            <Modal
                size="sm"
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                style={{ background: "#00000080" }}
            >
                <Modal.Body>
                    <Form.Group controlId="number" as={Row}>
                        <Col sm={12}>
                            <Input
                                type="text"
                                name="number"
                                defaultValue={userAccessEditData?.config}
                                placeholder={`Edit Whatsapp No`}
                                onKeyPress={e => handleKeyPress(e)}
                                disabled={disabled ? true : false}
                            />
                        </Col>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        Cancel
                    </Button>
                    <> </>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default WhatsappEdit;