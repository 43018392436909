import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

export function ViolationNotificationEditDialogHeader({ data}) {


  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
            {data?.vendor_details?.name}
        </Modal.Title>
      </Modal.Header>
    </>
  );
}
