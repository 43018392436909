import React from "react";
import MyAllCameraCard from "./Components/MyAllCameraTable/MyAllCameraCard";

function MyAllCamera(props) {
  return (
    <div>
        <MyAllCameraCard />
    </div>
  );
}

export default MyAllCamera;
