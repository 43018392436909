import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import Rating from '@material-ui/lab/Rating';

export function FeedbackEditForm({
                                     saveFeedback,
                                     feedbackData,
                                     onHide,
                                 }) {

    const [formData, setFormData] = useState({
        feedbackMessage: '',
        ratings: '',
        id: '',
    });

    const setRating = (rating) => {
        if (rating)
            setFormData({...formData, ratings: rating});
    }

    const handleChange = (e) => {
        let data = {...formData};
        data[e.target.name] = e.target.value;
        setFormData(data);
    }

    useEffect(() => {
        setFormData({
            feedbackMessage: feedbackData?.feedback_message || '',
            ratings: feedbackData?.ratings || 3,
            id: feedbackData?.id || null
        });
    }, [feedbackData])

    const handleSubmit = () => {
        saveFeedback(formData);
    }

    return (
        <>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="ratings" as={Row}>
                        <Form.Label column sm={12} className="text-center">
                            <Rating
                                sizeLarge
                                name="ratings"
                                value={formData.ratings}
                                onChange={(event, newValue) => {
                                    setRating(newValue);
                                }}
                            />
                        </Form.Label>
                    </Form.Group>
                    <Form.Group controlId="feedbackMessage" as={Row}>
                        <Form.Label column sm={12}>
                            Feedback Message
                        </Form.Label>
                        <Col sm={12}>
                            <Form.Control as="textarea"
                                          rows={5}
                                          name="feedbackMessage"
                                          placeholder="Feedback Message"
                                          value={formData.feedbackMessage}
                                          onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                >
                    Cancel
                </Button>
                <> </>
                <Button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-primary btn-elevate"
                >
                    Save
                </Button>
            </Modal.Footer>

        </>
    );
}
