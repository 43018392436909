import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../layout";
import Demo2Dashboard from "./Demo2Dashboard";
import {shallowEqual, useSelector} from "react-redux";
import Demo2TopspinDashboard from "./Demo2TopspinDashboard";

export function Dashboard() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      demo: objectPath.get(uiService.config, "demo"),
    };
  }, [uiService]);

  const { user } = useSelector(
      ({ auth }) => ({
        user: auth.user
      }),
      shallowEqual
  );

   if (user.user_email === "topspin_supervisor@tusker.ai") {
    return <>{layoutProps.demo === "demo1" && <Demo2TopspinDashboard />}</>
  } else  {
    return <>{layoutProps.demo === "demo1" && <Demo2Dashboard />}</>
  }

}
