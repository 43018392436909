import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { shallowEqual, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import Tooltip from "@material-ui/core/Tooltip";
import Boundingbox from "image-bounding-box-custom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const useStyles = makeStyles({
  card: {
    maxWidth: 254,
    height: "100%",
    margin: "auto",
    marginTop: "15px"
  },
  media: {
    height: 290
  },
  header: {
    paddingBottom: "0rem"
  },
  learnMore: {
    position: "absolute",
    bottom: 0
  },

  modalContent: {
    height: 100
  }
});

export function MyEventViewDialog({ show, onHide, event, cameraName }) {
  const [modaldata, setModaldata] = useState([]);
  const classes = useStyles();

  const columns = [
    {
      dataField: "event_name",
      text: "event"
    },
    {
      dataField: "event_type",
      text: "event type"
    },
    {
      dataField: "EVENT DESCRIPTION",
      text: "Event DESCRIPTION",
      formatter: () => {
        return (
          <>
            <Tooltip
              className="tools"
              title={<div className="tooltip-font">{event.event_desc}</div>}
              placement={"bottom"}
            >
              <div
                style={{ width: "200px" }}
                className="short-label-name-length"
              >
                {event.event_desc}
              </div>
            </Tooltip>
          </>
        );
      }
    },
    {
      dataField: "camera_name",
      text: "Camera Name"
    },
    {
      dataField: "date",
      text: "Event Date"
    }
  ];

  useEffect(() => {
    if (Object.keys(event).length > 0) {
      getColumnsAndData();
      //eslint-disable-next-line
    }
  }, [event]);

  const { entities } = useSelector(
    state => ({
      entities: state.myEvent.entities
    }),
    shallowEqual
  );

  const getColumnsAndData = () => {
    let modal_data = [];
    let camera_name = cameraName[event.camera_id];
    let event_name = event.event_name;
    let event_desc = event.event_desc;
    let event_type = event.event_type;
    let Date = moment
      .utc(event?.event_date.$date)
      .local()
      .format("MMMM DD YYYY, h:mm:ss a");

    modal_data.push({
      camera_name: camera_name,
      event_name: event_name,
      event_desc: event_desc,
      event_type: event_type,
      date: Date
    });
    setModaldata(modal_data);
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      dialogClassName="result-modal modalNewSize"
    >
      <Modal.Header closeButton={onHide}>
        <h3 id="example-modal-sizes-title-lg">Event Details</h3>
      </Modal.Header>
      <Modal.Body style={{ padding: "0.50rem" }}>
        <>
          {event && (
            <BootstrapTable
              classes="table table-head-custom table-vertical-center overflow-hidden mt-3"
              bootstrap4
              wrapperClasses="table-responsive"
              keyField="label"
              bordered={false}
              data={modaldata}
              columns={columns}
            />
          )}
        </>
        <div>
          {event?.image_list && event?.image_list ? (
            <Row>
              <Col xl={6} md={6} sm={12} lg={6}>
                <TransformWrapper
                  defaultScale={1}
                  defaultPositionX={200}
                  defaultPositionY={100}
                >
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                      <div
                        className="tools"
                        style={{ width: "100%", marginBottom: "4px" }}
                      >
                        <ButtonGroup
                          size="small"
                          aria-label="Small outlined button group"
                        >
                          <Button onClick={zoomIn}>+</Button>
                          <Button onClick={zoomOut}>-</Button>
                          <Button onClick={resetTransform}>reset</Button>
                        </ButtonGroup>
                      </div>
                      <div
                        className="boundimage-full w-100"
                        style={{ margin: "0 auto" }}
                      >
                        <TransformComponent>
                          <div className={"mt-5 mb-5"}>
                            <CardMedia
                              style={{ cursor: "pointer" }}
                              alt={"Image Here 1"}
                            >
                              <Boundingbox
                                className="row m-auto col-12 p-0 text-center"
                                image={
                                  event.image_list[0]?.imageUrl
                                    ? event.image_list[0]?.imageUrl
                                    : event.image_list[0]
                                }
                                options={{
                                  colors: {
                                    normal: "red",
                                    selected: "red",
                                    unselected: "red"
                                  },
                                  style: {
                                    maxWidth: "100%",
                                    maxHeight: "100vh",
                                    margin: "auto",
                                    width: "100vw",
                                    color: "red",
                                    height: 510
                                  }
                                }}
                              />
                            </CardMedia>
                          </div>
                        </TransformComponent>
                      </div>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              </Col>
              <Col xl={6} md={6} sm={12} lg={6}>
                <TransformWrapper
                  defaultScale={1}
                  defaultPositionX={200}
                  defaultPositionY={100}
                >
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                      <div
                        className="tools"
                        style={{ width: "100%", marginBottom: "4px" }}
                      >
                        <ButtonGroup
                          size="small"
                          aria-label="Small outlined button group"
                        >
                          <Button onClick={zoomIn}>+</Button>
                          <Button onClick={zoomOut}>-</Button>
                          <Button onClick={resetTransform}>reset</Button>
                        </ButtonGroup>
                      </div>
                      <div
                        className="boundimage-full w-100"
                        style={{ margin: "0 auto" }}
                      >
                        <TransformComponent>
                          <div className={"mt-5 mb-5"}>
                            <CardMedia
                              style={{ cursor: "pointer" }}
                              alt={"Image Here 1"}
                            >
                              <Boundingbox
                                className="row m-auto col-12 p-0 text-center"
                                image={
                                  event.image_list[event.image_list.length - 1]
                                    ?.imageUrl
                                    ? event.image_list[
                                        event.image_list.length - 1
                                      ]?.imageUrl
                                    : event?.image_list[
                                        event.image_list.length - 1
                                      ]
                                }
                                options={{
                                  colors: {
                                    normal: "red",
                                    selected: "red",
                                    unselected: "red"
                                  },
                                  style: {
                                    maxWidth: "100%",
                                    maxHeight: "100vh",
                                    margin: "auto",
                                    width: "100vw",
                                    color: "red",
                                    height: 510
                                  }
                                }}
                              />
                            </CardMedia>
                          </div>
                        </TransformComponent>
                      </div>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ display: "block" }}>
        <Row>
          <Col xl={6} lg={6} xs={12} md={6} sm={12}>
            {event?.image_list[0] && event?.image_list[0]?.imageDate ? (
              <span className={"font-weight-bolder"}>
                Entry Time :{" "}
                {moment
                  .utc(event?.image_list[0]?.imageDate)
                  .local()
                  .format("DD MMMM YYYY, h:mm:ss a")}{" "}
                - Exit Time :{" "}
                {moment
                  .utc(
                    event?.image_list[event?.image_list.length - 1]?.imageDate
                  )
                  .local()
                  .format("DD MMMM YYYY, h:mm:ss a")}{" "}
              </span>
            ) : (
              <></>
            )}
          </Col>
          <Col
            xl={6}
            lg={6}
            xs={12}
            md={6}
            sm={12}
            className={"d-flex justify-content-end"}
          >
            <Button
              type="button"
              onClick={() => onHide(false)}
              className="btn btn-light btn-elevate"
            >
              Close
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

const useStylesCard = makeStyles({
  card: {
    maxWidth: 416,
    height: "100%",
    margin: "auto"
  },
  header: {
    paddingBottom: "0rem"
  },
  learnMore: {
    position: "absolute",
    bottom: 0
  },
  cardCol: {
    height: 320,
    marginTop: 25,
    marginBottom: 15
  }
});
